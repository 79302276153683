import {makeStyles} from "@material-ui/styles";
import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import {AppContext} from "../../AppContext";
import {useParams} from "react-router-dom";
import {Button, Typography} from "@material-ui/core";
import {errorsToObject} from "../../components/Forms/form_utils";
import Axios from "axios";
import Minimal from "../../layouts/Minimal/Minimal";
import {RegFormType} from "../../api";
import {BarRegistrationForm} from "./BarRegistrationForm";
import useTheme from "@material-ui/core/styles/useTheme";

const useStyles = makeStyles(theme => ({
  regFormContainer: {
    width: '100%',
    height: '100%',
    zIndex: 1000000,
    // paddingTop: 64,
    // ["@media (max-width:700px)"] : {
    //   paddingTop: 56,
    // },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default
  },

  regFormContainerCustom2: {
    width: '100%',
    // zIndex: 1000000,
    // paddingTop: 64,
    // ["@media (max-width:700px)"] : {
    //   paddingTop: 56,
    // },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: "url(/images/custom2/ff3.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // backgroundColor: theme.palette.background.default
    ["@media (min-height:800px)"] : {
      height: '100%',
    },
  },

  regFormContainerCustom3: {
    position: 'sticky', 
    top: 0, 
    left: 0, 
    width: '100vw',
    height: '100vh',  
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    backgroundImage: "url(/images/custom3/serb.jpg)", 
    backgroundRepeat: "no-repeat", 
    backgroundSize: "cover",
  },

  regFormOuter: {
    width: 600,
    // height: 700,
    ["@media (max-width:700px)"] : {
      width: '100%',
      height: '100%'
    },
  },
}));

const getQueryVariable = (variable) => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return undefined;
};

const strings = {
  "ru": {
    regDisabled: "Регистрация выключена",
    anotherRegistration: "Зарегистрироваться с другими данными",
    registrationCanceled: "Регистрация отменена.",
    editRegistration: "Редактировать запись",
    editRegistration2: "Изменение регистрации на",
    registrationFor: "Регистрация на",

    registrationCreateButton: "Зарегистрироваться",
    registrationCancelButton: "Отменить запись",
    registrationUpdateButton: "Обновить запись",
    registrationRestoreButton: "Восстановить запись",

    acceptPolicy1: "Я принимаю",
    acceptPolicy2: "Политику конфединциальности"
  },
  "en": {
    regDisabled: "Регистрация выключена",
    anotherRegistration: "Register with different data",
    registrationCanceled: "Registration canceled",
    editRegistration: "Edit registration",
    editRegistration2: "Registration change for",
    registrationFor: "Registration for",

    registrationCreateButton: "Register",
    registrationCancelButton: "Cancel registration",
    registrationUpdateButton: "Update registration",
    registrationRestoreButton: "Restore registration",

    acceptPolicy1: "I accept the",
    acceptPolicy2: "privacy policy"

  }
}

export const RegFormContext = createContext({});

const RegForm = () => {
  const classes = useStyles();
  const theme = useTheme();
  const appContext = useContext(AppContext);
  const { token } = useParams();
  const visitorToken = getQueryVariable("visitor")
  const [regFormData, setRegFormData] = useState();
  const [tt, setT] = useState({});

  const [completionStatus, setCompletionStatus] = useState(null);
  const [refresh, setRefresh] = useState(0);

  const [data, setData] = useState({
    extended: {}
  });
  const formRef = useRef();

  const [errors, setErrors] = useState({});

  useEffect(() => {
    appContext.regFormApi
      .getForm(token)
      .then(({ data }) => {
        setRegFormData(data);
        setT(strings[data.regForm.language])
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, refresh]);

  useEffect(() => {
    if (visitorToken) {
      appContext.regFormApi
        .getVisitorByToken(token, visitorToken)
        .then(({ data }) => {
          console.log(data)
          setData(data);
        })
        .catch(appContext.errorHandler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, visitorToken, refresh]);

  const handleSave = async () => {
    const data = {};
    const valid = await formRef.current.fillValue(data);

    if (valid) {
      Axios.post("/api/form/" + token, data).then(({data}) => {
        setCompletionStatus("registered");
        setErrors({})
        setData(data)
      }).catch((error) => {
        setErrors(errorsToObject(error));
      })
    }
  };

  const handleUpdate = async () => {
    const data = {};
    const valid = await formRef.current.fillValue(data);

    if (valid) {
      appContext.regFormApi.updateVisitorByToken(token, visitorToken, data)
        .then(({ data }) => {
          setCompletionStatus("updated");
          setErrors({})
          setData(data)
        }).catch((error) => {
        setErrors(errorsToObject(error));
      })
    }
  };

  const handleCancel = async () => {
    appContext.regFormApi.cancelVisitorByToken(token, visitorToken)
      .then(({ data }) => {
        setCompletionStatus("canceled");
        setErrors({})
      }).catch((error) => {
        setErrors(errorsToObject(error));
      })
  };


  const handleOnceAgain = async () => {
    setData({
      extended: {}
    });
    setCompletionStatus("null");
    setRefresh(refresh + 1)
    if (window.location.href.indexOf('?') !== -1) {
      appContext.history.push(
        "/regform/" + regFormData.regForm.token
      );
    }
  };

  const handleEdit = async () => {
    setData({
      extended: {}
    });
    setCompletionStatus("null");
    setRefresh(refresh + 1)
    appContext.history.push(
      "/regform/" + regFormData.regForm.token + "?visitor=" + data.qrToken
    );
  };

  if (!regFormData) {
    return null
  }

  const customStyles =
    token === "e60322ef-d158-4bb6-ae3c-d5d69eef2eb5" ? classes.regFormContainerCustom2 
    : (token === "04a5817a-d2f7-4969-9a9b-79331f93fe75" ? classes.regFormContainerCustom3 : classes.regFormContainer);

  // проверить включена ли
  if (!regFormData.regForm.enabled) {
    return (
      <Minimal>
        <div className={customStyles}>
          <div className={classes.regFormOuter}>
            <Typography variant={"h3"} style={{textAlign:"center"}}>{tt.regDisabled}</Typography>
          </div>
        </div>
      </Minimal>
    )
  }

  // проверить не закрыта ли
  if (regFormData.closed) {
    return (
      <Minimal>
        <div className={customStyles}>
          <div className={classes.regFormOuter} style={{textAlign:"center"}}>
            <Typography variant={"h3"} style={{whiteSpace: 'pre-line'}}>{regFormData.regForm.closedText}</Typography>
          </div>
        </div>
      </Minimal>
    )
  }

  // проверить на завершена ли
  if (completionStatus === "registered" || completionStatus === "updated") {
    return (
      <Minimal>
        <div className={customStyles}>
          <div className={classes.regFormOuter} style={{textAlign:"center"}}>
            <Typography variant={"h3"} style={{whiteSpace: 'pre-line', marginBottom: "16px"}}>{regFormData.regForm.completionText}</Typography>

            <Button color='primary' variant='contained' style={{textAlign:"center", width: 300}} onClick={() => handleEdit()}>
              {tt.editRegistration}
            </Button>

            <br/>
            <br/>

            <Button color='primary' variant='contained' style={{textAlign:"center", width: 300}} onClick={() => handleOnceAgain()}>
              {tt.anotherRegistration}
            </Button>
          </div>
        </div>
      </Minimal>
    )
  }

  if (completionStatus === "canceled") {
    return (
      <Minimal>
        <div className={customStyles}>
          <div className={classes.regFormOuter} style={{textAlign:"center"}}>
              <Typography variant={"h3"} style={{whiteSpace: 'pre-line', marginBottom: "16px"}}>{tt.registrationCanceled}</Typography>

              <Button color='primary' variant='contained' style={{textAlign:"center", width: 300}} onClick={() => handleEdit()}>
                {tt.editRegistration}
              </Button>

              <br/>
              <br/>

              <Button color='primary' variant='contained' style={{textAlign:"center", width: 300}} onClick={() => handleOnceAgain()}>
                {tt.anotherRegistration}
              </Button>
          </div>
        </div>
      </Minimal>
    )
  }

  if (visitorToken && !data.visitorId) {
    return null
  }

  // вывести форму соответствующего типа
  return (
    <RegFormContext.Provider value={regFormData}>
    <Minimal>
        <div className={customStyles}>
          <div className={classes.regFormOuter}>
            <BarRegistrationForm regFormData={regFormData} formRef={formRef} handleSave={handleSave} handleUpdate={handleUpdate} handleCancel={handleCancel} errors={errors} data={data} tt={tt}/>
          </div>
        </div>
    </Minimal>
    </RegFormContext.Provider>
  )

}

export default RegForm;

